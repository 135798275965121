import {
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, BarChart, ResponsiveContainer
} from 'recharts';

const CustomTick = ({ x, y, payload }) => {
  const wrappedText = payload.value.split(' ').map((word, index) => (
    <tspan key={index} x={x} dy={index === 0 ? 0 : 15}>
      {word}
    </tspan>
  ));
  return (
    <text x={x} y={y+60} textAnchor="middle">
      {wrappedText}
    </text>
  );
};

const BarChartDisplay = (chart, dataKeyMapping, CustomTooltip) => {
  const { xKey, yKey } = dataKeyMapping[chart.filters.timelineType];
  const chartData = chart.data.totals;

  return (
    <ResponsiveContainer width={'100%'} height={500}>
      <BarChart data={chartData} style={{
        border: '1px solid black',
        borderRadius: '8px',
      }}
      margin={{ top: 5, right: 30, left: 50, bottom: 70 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={xKey} textAnchor="end" angle={30} tick={<CustomTick/>} />
        <YAxis allowDecimals={false}/>
      <Legend formatter={(value, entry) => {
            if (chart.filters.timelineType === 'custom') {
                if (entry.dataKey === yKey) return `${chart.filters.startPeriod} - ${chart.filters.endPeriod}`;
            } else {
                if (entry.dataKey === yKey) return `${chart.year}`;
            }
            return value;
        }}
        />
        <Bar dataKey={yKey} fill="#8884d8" />
        <Tooltip content={<CustomTooltip />} />
      </BarChart>
    </ResponsiveContainer>
  );
} 

export default BarChartDisplay;
