import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const ComparisonTable = ({ chart }) => {
  const { totals, comparisonTotals } = chart.data;

  if (!totals) {
    return <p>Loading...</p>; // Display a loading message or skeleton
  }

  // Merge totals and comparisonTotals
  const mergedData = totals.map((item) => {
    // Find the matching comparison data by location
    const comparison = comparisonTotals?.find(
      (comp) => comp.location === item.location
    ) || {};

    // Calculate the percentage difference
    const percentageDifference =
      item.totals > 0
        ? ((item.totals - (comparison.comparisonTotals || 0)) / item.totals) * 100
        : -comparison.comparisonTotals * 100; // Avoid division by zero

    return {
      location: item.location,
      totals: item.totals,
      comparisonTotals: comparison.comparisonTotals || 0,
      percentageDifference: percentageDifference.toFixed(2), // Keep 2 decimal points
    };
  });

  const totalMainYear = mergedData.reduce((acc, row) => acc + row.totals, 0);
  const totalComparisonYear = mergedData.reduce(
    (acc, row) => acc + row.comparisonTotals,
    0
  );

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: '50%', // Shrink width to 50%
        margin: 'auto', // Center the table horizontally
        boxShadow: 3, // Add some shadow for aesthetics
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Location</TableCell>
            <TableCell align="right">Main Year: {chart.year}</TableCell>
            {(comparisonTotals && chart.type === 'line') && comparisonTotals.length > 0 && (
              <>
                <TableCell align="right">Comparison Year: {chart.comparisonYear}</TableCell>
                <TableCell align="right">Difference (%)</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {mergedData.map((row) => (
            <TableRow
              key={row.location}
              sx={{
                '&:hover': {
                  backgroundColor: '#f5f5f5', // Change row color on hover
                  cursor: 'pointer', // Change cursor to indicate interactivity
                },
              }}
            >
              <TableCell>{row.location}</TableCell>
              <TableCell align="right">{row.totals}</TableCell>
              {(comparisonTotals && chart.type === 'line') && comparisonTotals.length > 0 && (
                <>
                  <TableCell align="right">{row.comparisonTotals}</TableCell>
                  <TableCell
                    align="right"
                    style={{ color: row.percentageDifference > 0 ? 'red' : 'green' }}
                  >
                    {row.percentageDifference}%
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
          {/* Final Totals Row */}
          <TableRow
            sx={{
              fontWeight: 'bold',
              backgroundColor: '#e0e0e0', // Highlight the totals row
            }}
          >
            <TableCell>Total</TableCell>
            <TableCell align="right">{totalMainYear}</TableCell>
            {(comparisonTotals && chart.type === 'line') && comparisonTotals.length > 0 && (
              <>
                <TableCell align="right">{totalComparisonYear}</TableCell>
                <TableCell 
                  align="right"
                  style={{ color: (((totalMainYear - totalComparisonYear) / totalMainYear) * 100) > 0 ? 'red' : 'green' }}
                >
                  {/* Calculate overall percentage difference */}
                  {(
                    ((totalMainYear - totalComparisonYear) / totalMainYear) *
                    100
                  ).toFixed(2)}%
                </TableCell>
              </>
            )}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ComparisonTable;
