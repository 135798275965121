import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const InlineDateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: 'flex',
          // margin: 'auto',
          flexDirection: 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            margin: 'auto',
            flexDirection: 'column', // Stack items vertically
            gap: 1,
            maxWidth: '150px', // Restrict width to align with other headers
            alignItems: 'center',
          }}
        >
          <DatePicker
            label="Start"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  backgroundColor: '#E3E8F7',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { border: 'none' },
                    '&:hover fieldset': { border: 'none' },
                    '&.Mui-focused fieldset': { border: 'none' },
                  },
                }}
              />
            )}
          />
          <DatePicker
            label="End"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                sx={{
                  backgroundColor: '#E3E8F7',
                  borderRadius: '8px',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': { border: 'none' },
                    '&:hover fieldset': { border: 'none' },
                    '&.Mui-focused fieldset': { border: 'none' },
                  },
                }}
              />
            )}
          />
        </Box>

        <IconButton
          onClick={handleClear}
          size="small"
          sx={{
            color: '#888',
            '&:hover': {
              color: '#000',
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </LocalizationProvider>
  );
};

export default InlineDateRangePicker;
