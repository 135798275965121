import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Checkbox, FormControlLabel, TextField, FormGroup, Box, Button, Typography, InputLabel } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '../assets/add.png';
import ChartAutocomplete from './Charts/ChartAutocomplete';
import LineChartDisplay from './Charts/LineChartDisplay';
import PieChartDisplay from './Charts/PieChartDisplay';
import BarChartDisplay from './Charts/BarChartDisplay';
import ComparisonTable from './Charts/ComparisonTable';
import api from '../context/api';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import useChartData from '../hooks/useChartData';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Visualization = () => {

  const {
    filters,
    id,
    setIndex,
    isLoading,
    setIsLoading,
    charts,
    setCharts,
    checked,
    setChecked,
    customDates,
    setCustomDates,
    locations,
    setLocations,
    causes,
    setCauses,
    sources,
    setSources,
    handleSourceChange,
    handlePageChange,
    handlePerPageChange,
    handlePageSelection,
    handleSecondPageChange,
    handleDirectPeriodChange,
    handleChangeChartType,
    handleComparisonYearChange,
    handleYearChange,
    handleChange,
    handleDateChange,
    handleLocationChange,
    handleCauseChange,
  } = useChartData();

  const token = localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));

  const addNewChart = () => {
    setIsLoading(true);
    setCharts(currentCharts => [
      ...currentCharts,
      {
        data: [],
        filters: { ...filters },
        type: 'line',
        year: new Date().getFullYear(),
        comparisonYear: '',
        page: 1,
        perPage: 10,
        totalPages: 1,
        secondPage: 1,
        totalPeriodPages: 13,
        locations: [],
        sources: [],
        causes: [],
      }
    ]);
    setChecked(currentChecked => [
      ...currentChecked,
      {
        annual: true,
        quarterly: false,
        '13periods': false,
        custom: false
      }
    ]);
    setCustomDates(currentDates => [
      ...currentDates,
      {
        startPeriod: '',
        endPeriod: ''
      }
    ]);
  };

  const onClickCustom = (index) => {
    const { startPeriod, endPeriod } = customDates[index];
    setCharts(currentCharts => currentCharts.map((chart, chartIndex) => {
      if (index === chartIndex) {
        return {
          ...chart,
          filters: {
            ...chart.filters,
            startPeriod: startPeriod,
            endPeriod: endPeriod,
            timelineType: 'custom'
          }
        };
      }
      return chart;
    }));
    setIndex(index);
  }

  // Fetch data, process it according to timeline and period, and update state
  const timeLine = async (index) => {
    setIsLoading(true);
    const chart = charts[index];
    const isAnnualPieChart = chart.type === 'pie' && chart.filters.timelineType === 'annual';
    const perPage = isAnnualPieChart ? 50 : chart.perPage;

    try {
      const response = await api.get(`/graph`, {
        params: {
          ...chart.filters,
          year: chart.year,
          comparisonYear: chart.comparisonYear,
          locations: chart.locations.map(location => location.id),
          causes: chart.causes.map(cause => cause.id),
          sources: chart.sources.map(source => source.id),
          page: chart.page,
          perPage: perPage,
          secondPage: chart.secondPage, // Period pagination
        },
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        setCharts(currentCharts => currentCharts.map((chart, idx) => {
          if (idx === index) {
            return {
              ...chart,
              data: response.data,
              totalPages: response.data.totalPages
            };
          }
          return chart;
        }));
      }

    } catch (err) {
      console.log("An error ocurred:", err);
    } finally {
      setIsLoading(false);
    }
  }

  const deleteChart = (chartIndex) => {
    // Update charts state
    setCharts(currentCharts => currentCharts.filter((_, index) => index !== chartIndex));

    // Update checked state
    setChecked(currentChecked => currentChecked.filter((_, index) => index !== chartIndex));

    // Update customDates state if needed
    setCustomDates(currentCustomDates => currentCustomDates.filter((_, index) => index !== chartIndex));
    setIndex(null);
  };

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await api.get('/locations-data', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setLocations(response.data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    const fetchCauses = async () => {
      try {
        const response = await api.get('/causes-data', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setCauses(response.data);
      } catch (error) {
        console.error('Error fetching causes:', error);
      }
    };

    const fetchSources = async () => {
      try {
        const response = await api.get('/sources-data', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setSources(response.data);
      } catch (error) {
        console.error('Error fetching causes:', error);
      }
    };

    fetchCauses();
    fetchLocations();
    fetchSources();
  }, []);

  // useEffect(() => {
  //   console.log('Is loading:', isLoading);  // This will log after locations state has updated
  //   console.log('Id:', id);
  // }, [charts]);

  useEffect(() => {
    if (id !== null) {
      timeLine(id);
      setIndex(null); // Reset the state after updating
    }
  }, [id]);

  useEffect(() => {
    const lastIndex = charts.length - 1;
    timeLine(lastIndex);
  }, [charts.length])

  // Graph to PDF
  const exportChartToPDF = async () => {
    const container = document.getElementById('recharts-container');

    // Use html2canvas to take a screenshot of the container
    const canvas = await html2canvas(container, {
      scale: 1, // Adjust scale if needed
      width: container.scrollWidth,
      height: container.scrollHeight,
      useCORS: true // If you're loading images from external domains
    });

    const imgData = canvas.toDataURL('image/png');

    // Use jsPDF to create a new PDF
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px', // Use 'px' to match html2canvas output
      format: [canvas.width, canvas.height] // Set the PDF page size to the canvas size
    });

    // Add the entire captured canvas to the PDF
    pdf.addImage(imgData, 'PNG', 0, 0, canvas.width, canvas.height);

    // Save the PDF
    pdf.save('chart-and-buttons.pdf');
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const comparisonData = payload.length > 1 ? payload[1].payload : null; // Check if comparison data is available
      const comparisonKey = data.location ? 'location' : 'cause';

      if (comparisonData !== null) {
        // const proportion = ((data.totals / data.total_entries) * 100).toFixed(2);
        // const comparisonProportion = comparisonData ? ((comparisonData.comparisonTotals / comparisonData.total_entries) * 100).toFixed(2) : null;
        // const totalsDifference = comparisonData ? (data.totals - comparisonData.comparisonTotals) : null;

        return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p><strong>{comparisonKey === "location" ? "Location:" : "Cause:"}</strong> {data[comparisonKey]}</p>
            <p><strong>Total entries (Year {data.year}):</strong> {data.total_entries}</p>
            <p><strong>Total {comparisonKey === "location" ? "Locations" : "Causes"} (Year {data.year}):</strong> {data.totals}</p>
            {/* <p><strong>Proportion by Total Entries (Year {data.year}):</strong> {proportion}%</p> */}
            {comparisonData && (
              <>
                <p><strong>Total entries (Comparison Year {comparisonData.year}):</strong> {comparisonData.total_entries}</p>
                <p><strong>Total {comparisonKey === "location" ? "Locations" : "Causes"} (Comparison Year {comparisonData.year}):</strong> {comparisonData.comparisonTotals}</p>
                {/* <p><strong>Proportion by Total Entries (Comparison Year {comparisonData.year}):</strong> {comparisonProportion}%</p> */}
                {/* <p><strong>Difference in Totals:</strong> {totalsDifference}</p> */}
              </>
            )}
          </div>
        );
      }
      else if (data.location || data.cause) {
        // const proportion = ((data.totals / data.total_entries) * 100).toFixed(2);
        return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p><strong>{data.location ? "Location:" : "Cause:"}</strong> {data.location ? data.location : data.cause}</p>
            <p><strong>Total entries:</strong> {data.total_entries}</p>
            <p><strong>Total Locations:</strong> {data.totals}</p>
            {/* <p><strong>Proportion by Total Entries:</strong> {proportion}%</p> */}
          </div>
        );
      } else {
        // For other timelines
        const locationsData = data.proportions?.location || [];
        const sourcesData = data.proportions?.source || [];
        const causesData = data.proportions?.cause || [];

        return (
          <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
            <p><strong>Total Entries:</strong> {data.totals}</p>
            {data.start_date && <p>Start date: {data.start_date}</p>}
            {data.end_date && <p>End date: {data.end_date}</p>}
            <p><strong>Locations:</strong></p>
            {locationsData.length > 0 ? (
              locationsData.map((loc) => (
                <p key={loc.name}>{loc.name}: {loc.percentage}</p>
              ))
            ) : (
              <p>No location data available.</p>
            )}
            <p><strong>Sources:</strong></p>
            {sourcesData.length > 0 ? (
              sourcesData.map((src) => (
                <p key={src.name}>{src.name}: {src.percentage}</p>
              ))
            ) : (
              <p>No source data available.</p>
            )}
            <p><strong>Causes:</strong></p>
            {causesData.length > 0 ? (
              causesData.map((cause) => (
                <p key={cause.name}>{cause.name}: {cause.percentage}</p>
              ))
            ) : (
              <p>No cause data available.</p>
            )}
          </div>
        );
      }
    }

    return null;
  };

  const dataKeyMapping = {
    annual: { xKey: 'location', yKey: 'totals' },
    quarterly: { xKey: 'location', yKey: 'totals' },
    custom: { xKey: 'location', yKey: 'totals' },
    '13periods': { xKey: 'location', yKey: 'totals' },
  };

  // Function to generate an array of years from 2018 to the current year
  const generateYearOptions = () => {
    const startYear = new Date().getFullYear() - 4;
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - startYear + 1 }, (_, index) => startYear + index);
    return years;
  };

  // Render the line chart using recharts
  if (isLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <Box component="main" sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        paddingBottom: '100px',
      }}>
        <DrawerHeader />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h5" component="h1" sx={{ fontSize: '25px', padding: 2 }}>
            Visualization
          </Typography>
          <Box>
            <Button
              variant="contained"
              color='primary'
              startIcon={<img src={Add} alt="Add" />}
              onClick={addNewChart}
              sx={{
                borderRadius: 4, marginRight: 3, maxHeight: '35px',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }}
            >
            </Button>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportChartToPDF}
              sx={{
                backgroundColor: '#182355', borderRadius: 4, marginRight: 3, maxHeight: '35px',
                '& .MuiButton-startIcon': {
                  margin: 0,
                },
              }}
            >
            </Button>
          </Box>
        </Box>

        {/* <ThemeProvider theme={themeChart}> */}
        <Box id="recharts-container"
          sx={{
            display: 'flex',
            flexDirection: "column",
            flexWrap: 'wrap', justifyContent: 'center', width: '100%'
          }}>
          {charts.map((chart, index) => (
            <Box>
              <Box key={index} sx={{ padding: 2, display: 'flex', justifyContent: 'center' }}>
                <ChartAutocomplete
                  values={locations}
                  selectedValues={chart.locations}
                  handleValueChange={(event, newValue) => handleLocationChange(index, newValue)}
                  element={"Locations"}
                />

                <ChartAutocomplete
                  values={sources}
                  selectedValues={chart.sources}
                  handleValueChange={(event, newValue) => handleSourceChange(index, newValue)}
                  element={"Sources"}
                />

                <ChartAutocomplete
                  values={causes}
                  selectedValues={chart.causes}
                  handleValueChange={(event, newValue) => handleCauseChange(index, newValue)}
                  element={"Causes"}
                />

              </Box>
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  marginBottom: 3,
                }}
              >
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: 2,
                  width: '95%'
                }}>
                  <Box>
                    <FormGroup row sx={{ gap: 2, alignItems: "center" }}>
                      {charts.length > 1 &&
                        <Button
                          variant="contained"
                          startIcon={<DeleteIcon />}
                          onClick={() => deleteChart(index)}
                          sx={{
                            backgroundColor: '#CE442C', color: 'white', borderRadius: 4, width: '30px',
                            '& .MuiButton-startIcon': {
                              margin: 0,
                            },
                            '&:hover': {
                              backgroundColor: 'red',
                              border: 'none'
                            },
                          }}
                        >
                        </Button>
                      }
                      <FormControlLabel
                        control={<Checkbox checked={chart.type === 'pie'} onChange={() => handleChangeChartType(index, 'pie')} />}
                        label="Pie Chart"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={chart.type === 'bar'} onChange={() => handleChangeChartType(index, 'bar')} />}
                        label="Bar Chart"
                      />
                      <FormControlLabel
                        control={<Checkbox checked={chart.type === 'line'} onChange={() => handleChangeChartType(index, 'line')} />}
                        label="Line Chart"
                      />

                      {(checked[index].annual || checked[index].quarterly || checked[index]['13periods'] || charts[index]) && (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
                          <FormControl>
                            <InputLabel id={`year-select-label-${index}`}>Year</InputLabel>
                            <Select
                              labelId={`year-select-label-${index}`}
                              id={`year-select-${index}`}
                              value={chart.year}
                              label="Year"
                              onChange={(event) => handleYearChange(index, event)}
                            >
                              {generateYearOptions().map(year => (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {chart.type === 'line' && (
                            <FormControl>
                              <Select
                                labelId={`year-select-label-${index}`}
                                id={`year-select-${index}`}
                                value={chart.comparisonYear}
                                label="Comparison Year"
                                onChange={(event) => handleComparisonYearChange(index, event)}
                                displayEmpty
                                renderValue={
                                  chart.comparisonYear !== null ? undefined : () => <span>Select Year</span>
                                }
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {generateYearOptions().map(year => (
                                  <MenuItem key={year} value={year}>{year}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </Box>
                      )}
                    </FormGroup>
                    <FormGroup row sx={{ gap: 2, alignItems: "center" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked[index].annual}
                            onChange={(event) => handleChange(index, event)}
                            name="annual"
                          />
                        }
                        label="Annual"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked[index].quarterly}
                            onChange={(event) => handleChange(index, event)}
                            name="quarterly"
                          />
                        }
                        label="Quarterly"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked[index]['13periods']}
                            onChange={(event) => handleChange(index, event)}
                            name="13periods"
                          />
                        }
                        label="13 periods"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked[index].custom}
                            onChange={(event) => handleChange(index, event)}
                            name="custom"
                          />
                        }
                        label="Custom"
                      />
                      {checked[index].custom && customDates[index] && (
                        <Box sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          marginTop: 1
                        }}>
                          <TextField
                            label="Start Date"
                            type="date"
                            name="startPeriod"
                            value={customDates[index].startPeriod}
                            onChange={(event) => handleDateChange(index, event)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TextField
                            label="End Date"
                            type="date"
                            name="endPeriod"
                            value={customDates[index].endPeriod}
                            onChange={(event) => handleDateChange(index, event)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ marginTop: 1 }}
                          />
                          <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={() => onClickCustom(index)}
                            sx={{
                              backgroundColor: '#182355', borderRadius: 4, width: '30px', marginTop: 1,
                              '& .MuiButton-startIcon': {
                                margin: 0,
                              },
                            }}
                          >
                          </Button>
                        </Box>
                      )}
                    </FormGroup>
                  </Box>
                  {chart.type === 'line' && LineChartDisplay(chart, dataKeyMapping, CustomTooltip, handlePageChange)}
                  {chart.type === 'pie' && PieChartDisplay(chart, index, CustomTooltip, handlePageChange)}
                  {chart.type === 'bar' && BarChartDisplay(chart, dataKeyMapping, CustomTooltip)}

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2, width: '500px' }}>
                    <Button disabled={chart.page === 1 || chart.totalPages <= 1} onClick={() => handlePageChange(chart, -1, index)}>
                      Previous
                    </Button>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel id={`select-page-label-${index}`}>Page</InputLabel>
                      <Select
                        labelId={`select-page-label-${index}`}
                        id={`select-page-${index}`}
                        value={chart.page}
                        onChange={(event) => handlePageSelection(event.target.value, index)}
                      >
                        {Array.from({ length: chart.totalPages }, (_, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            Page {i + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button disabled={chart.page === chart.totalPages || chart.totalPages <= 1} onClick={() => handlePageChange(chart, 1, index)}>
                      Next
                    </Button>

                    <FormControl sx={{ minWidth: 120, marginBottom: 2 }}>
                      {/* <InputLabel id={`entries-per-page-label`}>Entries Per Page</InputLabel> */}
                      <Select
                        labelId={`entries-per-page-label`}
                        id={`entries-per-page`}
                        value={chart.perPage}
                        onChange={(event) => handlePerPageChange(event.target.value, index)}
                      >
                        {[5, 10, 20, 25].map((option) => (
                          <MenuItem key={option} value={option}>
                            {option} Entries
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  {chart.filters.timelineType === '13periods' || chart.filters.timelineType === 'quarterly' ? (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2, width: '500px' }}>
                      {/* Previous Button */}
                      <Button disabled={chart.secondPage === 1} onClick={() => handleSecondPageChange(chart, -1, index)}>
                        {chart.filters.timelineType === '13periods' ? 'Previous Period' : 'Previous Quarter'}
                      </Button>

                      {/* Dropdown for Jumping to Period/Quarter */}
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel id={`select-period-quarter-label-${index}`}>
                          {chart.filters.timelineType === '13periods' ? 'Period' : 'Quarter'}
                        </InputLabel>
                        <Select
                          labelId={`select-period-quarter-label-${index}`}
                          id={`select-period-quarter-${index}`}
                          value={chart.secondPage}
                          onChange={(event) => handleDirectPeriodChange(chart, event.target.value, index)}
                        >
                          {Array.from(
                            { length: chart.filters.timelineType === '13periods' ? chart.totalPeriodPages : 4 },
                            (_, i) => (
                              <MenuItem key={i + 1} value={i + 1}>
                                {chart.filters.timelineType === '13periods' ? `Period ${i + 1}` : `Quarter ${i + 1}`}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>

                      {/* Next Button */}
                      <Button
                        disabled={
                          (chart.filters.timelineType === '13periods' && chart.secondPage === chart.totalPeriodPages) ||
                          (chart.filters.timelineType === 'quarterly' && chart.secondPage === 4)
                        }
                        onClick={() => handleSecondPageChange(chart, 1, index)}
                      >
                        {chart.filters.timelineType === '13periods' ? 'Next Period' : 'Next Quarter'}
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <ComparisonTable chart={chart} />
              <hr style={{ marginTop: '40px', border: '1px solid #ccc' }} />
            </Box>
          ))}
        </Box>
        {/* </ThemeProvider> */}
      </Box>
    );
  }
}
export default Visualization;
